body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "LadyRadical";
  src: local("LadyRadical"),
  url("./fonts/LadyRadical2Regular-0yAz.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "PressStart2P";
  src: local("PressStart2P"),
  url("./fonts/PressStart2P-vaV7.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "VerminVibes1989";
  src: local("VerminVibes1989"),
  url("./fonts/VerminVibes1989Regular-m77m.ttf") format("truetype");
  font-weight: normal;
}
